<template>
  <div class="height1">
    <!--      头部      -->
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions0" size="small" style="margin-right: 10px">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="用药人/下单手机号/处方药"
                  @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
              <!-- <el-form-item>
                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields"
                  worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                  <el-button type="warning" size="mini" class="el-icon-download">导出当前页数据</el-button>
                </download-excel>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="DataTotal" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet"
                  :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="mini" class="el-icon-download">导出全部数据</el-button>
                </download-excel>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 90px)" :header-cell-style="{background:'#F5F7FC'}">
          <el-table-column prop="drugUsage.usageName" label="用药人姓名" width="90px"> </el-table-column>
          <!-- <el-table-column prop="drugUsage.usageIdCard" label="身份证号" align="center" width="170px">
          </el-table-column> -->
          <!-- <el-table-column prop="drugUsage.usagePhone" label="手机号" align="center" width="110px">
          </el-table-column> -->
          <el-table-column prop="drugUsage" label="下单用户" align="center" width="110px">
            <template slot-scope="scope">
              <p>{{ scope.row.buyUserName }}</p>
              <p>{{ scope.row.buyUserAccount }}</p>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="drugUsage" label="与本人关系" align="center" width="90px">
            <template slot-scope="scope">
              与本人关系 0本人 1父母 2 子女 3其他
              <p v-if="scope.row.drugUsage.relation == 0">本人</p>
              <p v-if="scope.row.drugUsage.relation == 1">父母</p>
              <p v-if="scope.row.drugUsage.relation == 2">子女</p>
              <p v-if="scope.row.drugUsage.relation == 3">其他</p>
            </template>
          </el-table-column> -->
          <el-table-column prop="prescriptionCommodity.name" label="处方药" align="center" width="150px">
          </el-table-column>
          <!-- <el-table-column prop="commoditySpecifications.name" label="药品规格" width="150px">
          </el-table-column> -->
          <el-table-column prop="buyNum" label="订单" align="center" width="120px">
            <template slot-scope="scope">
              <p>{{ scope.row.commoditySpecifications.name}}</p>
              <p>数量：{{ scope.row.buyNum}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="prescriptionName" label="患者病例" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" plain @click="showCase(scope.row)">查看</el-button></template>
          </el-table-column>
          <el-table-column prop="createTime" label="申请时间" align="center" width="150px">
            <template slot-scope="scope">
              {{ scope.row.createTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column prop="state" label="状态" align="center" width="150px">
            <template slot-scope="scope">
              <!-- 0 待审核 10 已开方未购买 11 已购买 -1 拒绝 -2 超时订单关闭 -->
              <el-tag type="warning" v-if="scope.row.state == 0">待审核</el-tag>
              <el-tag type="success" v-if="scope.row.state == 10">已开方未购买</el-tag>
              <el-tag v-if="scope.row.state == 11">已购买</el-tag>
              <el-tag type="danger" v-if="scope.row.state == -1">拒绝</el-tag>
              <el-tag type="danger" v-if="scope.row.state == -20">超时未处理</el-tag>
              <el-tag type="info" v-if="scope.row.state ==-21">超时未支付</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="processingName" label="处理人" align="center" width="110px">
          </el-table-column>
          <el-table-column prop="processingTime" label="处理时间" align="center" width="150px">
            <template slot-scope="scope">
              {{ scope.row.processingTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="250px">
            <template slot-scope="scope">
              <span class="primary optionBtn" @click="showMsgRecord(scope.row, true)"
                v-if="scope.row.state == 0">问诊</span>
              <span class="warning optionBtn" @click="showFormDialog(scope.row, 10)"
                v-if="scope.row.state == 0 && scope.row.sessionId">确认开方</span>
              <span class="danger optionBtn" @click="showFormDialog(scope.row, -1)"
                v-if="scope.row.state == 0 && scope.row.sessionId">拒绝</span>
              <span class="success optionBtn" @click="showMsgInfo(scope.row)"
                v-if="scope.row.state != 0 && scope.row.state != 20">问诊记录</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog :title="type == 10 ? '确认开方' : '拒绝开方'" :visible.sync="formDialog" width="600px">
      <!-- <p class="line"></p> -->
      <div class="box">
        <el-row :gutter="10" type="flex" justify="center">
          <el-col :span="8">
            <el-image class="leftImg"
              :src="form.commodityFileUrl"
              fit="cover"></el-image>
          </el-col>
          <el-col :span="14">
            <div class="right">
              <div class="item flex-start">
                <div class="leftLable">处方药ID：</div>
                <div class="rightValue">{{ form.recordId }}</div>
              </div>
              <div class="item flex-start">
                <div class="leftLable">药品名称：</div>
                <div class="rightValue oneLine">{{ form.name }}</div>
              </div>
              <div class="item flex-start">
                <div class="leftLable">药品规格：</div>
                <div class="rightValue">{{ form.commoditySpecifications.name }}</div>
              </div>
            </div>
            <el-form ref="form" :model="form" :rules="rules" label-width="78px">
              <el-form-item label="药品数量">
                <el-input-number v-model="form.buyNum" :step="1" step-strictly :min="1" size="mini"
                  :disabled="type == -1"></el-input-number>
              </el-form-item>
              <el-form-item v-if="type == 10" label="用法用量" prop="remark">
                <el-input type="textarea" v-model="form.remark" placeholder="请输入用法用量" size="mini" />
              </el-form-item>
              <el-form-item v-else label="拒绝理由" prop="reasonsRefusal">
                <el-input type="textarea" v-model="form.reasonsRefusal" placeholder="请输入拒绝理由" size="mini" />
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看病例" :visible.sync="caseDialog" width="900px">
      <el-descriptions title="用药人信息">
        <el-descriptions-item label="姓名">{{ caseInfo.drugUsage.usageName }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ caseInfo.drugUsage.usagePhone }}</el-descriptions-item>
        <el-descriptions-item label="身份证号">{{ caseInfo.drugUsage.usageIdCard }}</el-descriptions-item>
        <el-descriptions-item label="年龄">{{ caseInfo.drugUsage.age }}</el-descriptions-item>
        <el-descriptions-item label="性别">{{ caseInfo.drugUsage.gender ? "男" : "女" }}</el-descriptions-item>
        <el-descriptions-item label="与本人关系">
          <p v-if="caseInfo.drugUsage.relation == 0">本人</p>
          <p v-if="caseInfo.drugUsage.relation == 1">父母</p>
          <p v-if="caseInfo.drugUsage.relation == 2">子女</p>
          <p v-if="caseInfo.drugUsage.relation == 3">其他</p>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="患者病史" v-if="caseInfo.drugUsage.deUsageMedicalHistories">
        <el-descriptions-item :label="item.medicalName"
          v-for="(item, index) in caseInfo.drugUsage.deUsageMedicalHistories" :key="index">
          <p v-if="item.medicalName == '肝功能'">{{ item.isHave ? '异常' : '正常' }}</p>
          <p v-else-if="item.medicalName == '肾功能'">{{ item.isHave ? '异常' : '正常' }}</p>
          <p v-else>{{ item.medicalDescription }}</p>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="确诊疾病" v-if="caseInfo.prescriptionDiagnosedDisease" :colon="false">
        <el-descriptions-item v-for="(item, index) in caseInfo.prescriptionDiagnosedDisease" :key="index">
          <el-tag @click="DetialDisease(item.diseaseHashKey)">{{ item.diseaseName }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="其他病史" v-if="caseInfo.drugUsage.drugUsageMedicalHistories" :colon="false">
        <el-descriptions-item v-for="(item, index) in caseInfo.drugUsage.drugUsageMedicalHistories" :key="index">
          <p v-if="item.medicalName.indexOf('肝功能') != -1 || item.medicalName.indexOf('肾功能') != -1">{{
            item.medicalName
          }}{{ item.isHave ? "异常" : "正常" }}</p>
          <p v-else>{{ item.isHave ? "有" : "无" }}{{ item.medicalName }}</p>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="已有处方" v-if="caseInfo.prescriptionFileRelation">
        <el-descriptions-item v-for="(item, index) in caseInfo.prescriptionFileRelation" :key="index">
          <img class="caseImg" :src="`${Services.Shopping + item.file.photoThumbnail}`"
            @click="handleImagePreview(item.file)" />
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <el-dialog :visible.sync="Image.dialogVisible">
      <img width="100%" :src="Services.Shopping + '/' + item.file.photoThumbnail"
        v-for="(item, index) in caseInfo.prescriptionFileRelation" :key="index" alt="">
    </el-dialog>
    <!-- 详情 -->
    <el-drawer title="详情" size="40%" :visible.sync="detaildrawer" direction="rtl" wrapperClosable>
      <DiseasePreview :diseaseHashKey="diseaseHashKey"></DiseasePreview>
    </el-drawer>
    <el-drawer title="问诊记录" :visible.sync="dialogMsgInfo" :direction="direction" wrapperClosable>

      <div class="drawer-box">
        <div class="detail-item">
          <el-descriptions title="用药人信息" :column="2">
            <el-descriptions-item label="姓名">{{ caseInfo.drugUsage.usageName }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ caseInfo.drugUsage.usagePhone }}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{ caseInfo.drugUsage.usageIdCard }}</el-descriptions-item>
            <el-descriptions-item label="年龄">{{ caseInfo.drugUsage.age }}</el-descriptions-item>
            <el-descriptions-item label="性别">{{ caseInfo.drugUsage.gender ? "男" : "女" }}</el-descriptions-item>
            <el-descriptions-item label="与本人关系">
              <p v-if="caseInfo.drugUsage.relation == 0">本人</p>
              <p v-if="caseInfo.drugUsage.relation == 1">父母</p>
              <p v-if="caseInfo.drugUsage.relation == 2">子女</p>
              <p v-if="caseInfo.drugUsage.relation == 3">其他</p>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="detail-item">
          <div class="status">
            <el-tag type="warning" v-if="caseInfo.state == 0">待审核</el-tag>
            <el-tag type="success" v-if="caseInfo.state == 10">已开方未购买</el-tag>
            <el-tag v-if="caseInfo.state == 11">已购买</el-tag>
            <el-tag type="danger" v-if="caseInfo.state == -1">拒绝</el-tag>
            <el-tag type="info" v-if="caseInfo.state < -1">超时订单关闭</el-tag>
          </div>
          <el-descriptions title="药品信息" :column="1">
            <el-descriptions-item label="药品名称">{{ caseInfo.prescriptionCommodity.name }}</el-descriptions-item>
            <el-descriptions-item label="规格">{{ caseInfo.commoditySpecifications.name }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="detail-item">
          <el-descriptions title="处理人信息" :column="2">
            <el-descriptions-item label="处理人">{{ caseInfo.processingName }}</el-descriptions-item>
            <el-descriptions-item label="处理时间">{{ caseInfo.processingTime | timefilters }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="detail-item">
          <el-timeline>
            <el-timeline-item v-for="(item, index) in messageList" :key="index" color="#67C23A"
              :timestamp="item.timestamp | timefilters" placement="top">
              <p class="status-color user" v-if="caseInfo.buyUserId == item.fromUserId"><i
                  class="el-icon-s-custom"></i>{{ caseInfo.buyUserName }}</p>
              <p class="status-color shop" v-if="caseInfo.processingId == item.fromUserId"><i
                  class="el-icon-s-shop"></i>{{ caseInfo.processingName }}
              </p>
              <span class="status-color" v-if="item.contentType == 'orderInfo'">
                <template v-if="caseInfo.state == 10">
                  <el-card class="box-card" style="width: 340px;height: auto;position: relative; color:#000000">
                    <div style="">
                      <div style="width: 85px; height: auto; position: absolute; right: 15px; top: 15px; z-index: 2;">
                        <img style="width: 100%; height: 100%;" :src="img1" />
                      </div>
                      <div
                        style="width: 300px; height: 50px; padding: 0 15px;background: #D2F4F5;display: flex; flex-direction: row; justify-content: flex-start !important; align-items: center;">
                        <div
                          style="width: 15px; height: auto;display: flex; flex-direction: column; justify-content: center; align-items: center;">
                          <img style="width: 100%; height: 100%;" :src="img2" />
                        </div>
                        <div style="margin-left:10px"><span class="title" style="font-weight: bold;">处方单</span> <span
                            style="color:#333333;font-size: 13px;"></span></div>
                      </div>
                      <div style="width: 300px; height: 150px; padding: 10px;">
                        <div
                          style="display: flex; flex-direction: row; justify-content: flex-start !important; align-items: center;height: 30px; line-height: 30px;">
                          <div style="width: 30px; ">患者</div>
                          <div style="width: 250px; padding: 0 5px;font-weight: bold;">{{
                            caseInfo.drugUsage.usageName
                          }}
                            {{ caseInfo.drugUsage.gender ? '男' : '女' }}{{ caseInfo.drugUsage.age }}岁</div>
                        </div>
                        <div
                          style="display: flex; flex-direction: row; justify-content: flex-start !important; align-items: center;height: 30px; line-height: 30px;">
                          <div style="width: 30px; ">诊断</div>
                          <div
                            style="width: 250px; padding: 0 5px;font-weight: bold;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                            <span v-for="(e, i) in caseInfo.prescriptionDiagnosedDisease" :key="i">{{
                              e.diseaseName
                            }}</span>
                          </div>
                        </div>
                        <div
                          style="display: flex; flex-direction: row; justify-content: flex-start !important; align-items: center;height: 30px; line-height: 30px;">
                          <div style="width: 30px; ">RP</div>
                          <div
                            style="width: 250px; padding: 0 5px;font-weight: bold;display: flex; flex-direction: row; justify-content: space-between !important;align-items: center;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                            {{ caseInfo.prescriptionCommodity.name }}</div>
                        </div>
                        <div class="flex-end" style="height: 30px; line-height: 30px;">
                          <div style="width: 30px; "></div>
                          <div
                            style="display: flex; flex-direction: row; justify-content: space-between !important; align-items: center;">
                            <p>{{ caseInfo.commoditySpecifications.name }}</p>
                            <p>x{{ caseInfo.buyNum }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-card>
                </template>
                <template v-if="caseInfo.state == -1">
                  <el-card class="box-card" style="width: 340px;height: auto;position: relative;">
                    <div style="">
                      <div
                        style="width: 85px; height: auto; position: absolute; right: 15px; top: 15px; z-index: 9999;">
                        <img style="width: 100%; height: 100%;" :src="img3" />
                      </div>
                      <div
                        style="width: 300px; height: 50px; padding: 0 15px;background: #D2F4F5;display: flex; flex-direction: row; justify-content: flex-start !important; align-items: center;">
                        <div
                          style="width: 15px; height: auto;display: flex; flex-direction: column; justify-content: center; align-items: center;">
                          <img style="width: 100%; height: 100%;" :src="img4" />
                        </div>
                        <div style="margin-left:10px"><span class="title" style="font-weight: bold;">处方单</span> <span
                            style="color:#333333;font-size: 13px;"></span></div>
                      </div>
                      <div style="width: 300px; height: 150px; padding: 10px;">
                        <div
                          style="display: flex; flex-direction: row; justify-content: flex-start !important; align-items: center;height: 30px; line-height: 30px;">
                          <div style="width: 30px;  color: #999999;">患者</div>
                          <div style="width: 250px; padding: 0 5px;font-weight: bold;">{{
                            caseInfo.drugUsage.usageName
                          }}
                            {{ caseInfo.drugUsage.gender ? '男' : '女' }}{{ caseInfo.drugUsage.age }}岁</div>
                        </div>
                        <div
                          style="display: flex; flex-direction: row; justify-content: flex-start !important; align-items: center;height: 30px; line-height: 30px;">
                          <div style="width: 30px;  color: #999999;">诊断</div>
                          <div
                            style="width: 250px; padding: 0 5px;font-weight: bold;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                            <span v-for="(e, i) in caseInfo.prescriptionDiagnosedDisease" :key="i">{{
                              e.diseaseName
                            }}</span>
                          </div>
                        </div>
                        <div
                          style="display: flex; flex-direction: row; justify-content: flex-start !important; align-items: center;height: 30px; line-height: 30px;">
                          <div style="width: 30px;  color: #999999;">RP</div>
                          <div
                            style="width: 250px; padding: 0 5px;font-weight: bold;display: flex; flex-direction: row; justify-content: space-between !important;align-items: center;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                            {{ caseInfo.prescriptionCommodity.name }}</div>
                        </div>
                        <div class="flex-end" style="height: 30px; line-height: 30px;">
                          <div style="width: 30px; "></div>
                          <div
                            style="color: #666666;display: flex; flex-direction: row; justify-content: space-between !important; align-items: center;">
                            <p>{{ caseInfo.commoditySpecifications.name }}</p>
                            <p>x{{ caseInfo.buyNum }}</p>
                          </div>
                        </div>
                      </div>
                      <div
                        style="height: 80px; margin:0 20px; padding-top: 10px; border-top:1px solid #E7E7E7;  color: #E31212; font-size: 12px;">
                        <p>拒绝理由：</p>
                        <p
                          style="overflow: hidden;  text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2;  -webkit-box-orient: vertical;  white-space: normal; ">
                          {{ caseInfo.reasonsRefusal }}</p>
                      </div>
                    </div>
                  </el-card>
                </template>
              </span>
              <span class="status-color" v-else-if="item.contentType == 'img'">
                <img style="width:60%;height:auto" :src='item.content' />
              </span>
              <span class="status-color" v-else>{{ item.content }}</span>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Prescription } from "../../../components/HospitalDomain/Patient/Prescription";
import { Patient } from "../../../components/HospitalDomain/Patient/Patient";
import { CodeNode } from "source-list-map";
import { Commodity } from "../../../components/HospitalDomain/ShopMall/commodity";
import DiseasePreview from '../../../components/DiseaseDomain/DiseasePreview.vue';
import { Msg } from '../../../components/HospitalDomain/Msg';

export default {
  name: "Prescription",
  components: {
    bread,
    DiseasePreview,
  },
  data() {
    var patient = new Patient(this.TokenClient, this.Services.Authorization);
    var prescription = new Prescription(this.TokenClient, this.Services.Drug);
    var commodity = new Commodity(this.TokenClient, this.Services.Shopping);
    var msg = new Msg(this.TokenClient, this.Services.Chathub);
    return {
      patientDomain: patient,
      PrescriptionDomain: prescription,
      CommodityDomain: commodity,
      MsgDomain: msg,
      organizationId: JSON.parse(sessionStorage.getItem("userInfo"))
        .organizationId,
      userId: JSON.parse(sessionStorage.getItem("userInfo")).id,
      tableData: [],
      searchForm: {
        statrTime: "",
        endTime: "",
        keyWord: "",
        datepicker: "",
      },
      userLogo: 'https://api.qyyl365.com/authorization/PC/userlogo.png',
      shopLogo: 'https://api.qyyl365.com/authorization/PC/shoplogo.png',
      img1: 'https://api.qyyl365.com/authorization/PC/yikaifang.png',
      img2: 'https://api.qyyl365.com/authorization/PC/yikaifang2.png',
      img3: 'https://api.qyyl365.com/authorization/PC/jujuekaifang.png',
      img4: 'https://api.qyyl365.com/authorization/PC/jujuekaifang2.png',
      FileUrl: this.Services.Shopping,
      formDialog: false,
      caseDialog: false,
      caseInfo: {
        drugUsage: {
          deUsageMedicalHistories: []
        },
        prescriptionCommodity: {
          name: ""
        },
        commoditySpecifications: {
          name: ""
        },
        prescriptionDiagnosedDisease: []
      },
      Image: {
        //单击图片上传控件中的图片时，放大显示
        dialogVisible: false,
        dialogImageUrl: '',
      },
      formTitle: "",
      type: 1,//1确认开方2拒绝开方
      form: {
        id: 1231,
        name: '头孢',
        buyNum: 1,
        remark: "",
        reasonsRefusal: '',
        commoditySpecifications: {
          name: ""
        }
      },
      detaildrawer: false,
      dialogMsgInfo: false,
      messageList: [],
      diseaseHashKey: "",
      rules: {
        remark: [
          { required: true, message: '请输入用法用量', trigger: 'blur' },
        ],
        reasonsRefusal: [
          { required: true, message: '请输入拒绝理由', trigger: 'blur' },
        ]
      },
      auditStatus: 1,
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      patientList: [],
      userList: JSON.parse(sessionStorage.getItem("UserSession")),
      title: '处方药审核',
      DataCount: 0,
      json_fields: {
        "姓名": {
          callback: val => {
            return val.patient.name
          }
        },
        "年龄": {
          callback: (val) => {
            return val.patient.age
          }
        },
        "手机号": {
          callback: (val) => {
            return val.patient.phone
          }
        },
        "身份证号": {
          callback: val => {
            return `CID：${val.patient.idNo}`
          }
        },
        "方剂名称": 'prescriptionName',
        "方剂数量": 'dosage',
        "开方时间": {
          filed: 'addTime',
          callback: (val) => {
            var val = val.addTime
            return this.$fliters.timefilters(val)
          }
        },
        "开方人": 'userName',
        "审核时间": {
          filed: 'auditTime',
          callback: (val) => {
            var val = val.auditTime
            return val == '0001-01-01T00:00:00' ? '未审核' : this.$fliters.timefilters(val)
          }
        },
      },
      direction: 'rtl',
    };
  },
  mounted() {
    this.GetList();
    this.messageList.reverse()
  },
  methods: {
    DetialDisease(diseaseHashKey) {
      if (diseaseHashKey) {
        this.diseaseHashKey = diseaseHashKey;
        this.detaildrawer = true;
      }
    },
    handleImagePreview(file) {
      this.Image.dialogImageUrl = this.Services.Shopping + '/' + file.filePath;
      this.Image.dialogVisible = true;
    },
    showCase(item) {
      this.caseInfo = item
      this.caseDialog = true
    },
    search() {
      this.PageIndex = 1;
      this.GetList();
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex;
      this.GetList();
    },
    async fetchData() {
      var _this = this;
      return await _this.getAllData()
    },
    getAllData() {
      var _this = this;
      return new Promise((resolve, reject) => {
        var item = _this.searchForm;
        if (_this.searchForm.datepicker) {
          item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
          item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
        } else {
          item.startTime = "";
          item.endTime = "";
        }
        _this.CommodityDomain.PrescriptionRecordManager(
          item.startTime,
          item.endTime,
          item.keyWord,
          1, _this.DataCount,
          function (data) {
            resolve(data.data.results);
          },
          function (err) {
            resolve('');
            console.log(err);
          });
      })

    },
    GetList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      // return new Promise((resolve, reject) => {
        _this.CommodityDomain.PrescriptionRecordManager(
          item.startTime,
          item.endTime,
          item.keyWord,
          _this.PageIndex, null,
          function (data) {
            _this.tableData = data.data.results;
            _this.PageIndex = data.data.pageIndex;
            _this.PageSize = data.data.pageSize;
            _this.PageTotal = data.data.pageCount;
            _this.DataTotal = data.data.dataTotal;
              _this.DataCount = data.data.dataTotal;
            // resolve(true)
          },
          function (error) {
            console.log(error);
            // resolve(false)
          }
        );
      // })
    },
    showFormDialog(item, type) {
      this.type = type
      this.form = {
        recordId: item.id,
        name: item.prescriptionCommodity.name,
        commoditySpecifications: item.commoditySpecifications,
        state: type == 10 ? true : false,
        buyNum: item.buyNum,
        reasonsRefusal: null,
        remark: null,
        buyUserId: item.buyUserId,
        prescriptionDiagnosedDisease: item.prescriptionDiagnosedDisease,
        drugUsage: item.drugUsage,
        prescriptionCommodity: item.prescriptionCommodity,
        sessionId: item.sessionId,
        commodityFileUrl: this.FileUrl +item.prescriptionCommodityFiles[0].filePath
      }
      this.formDialog = true
    },
    showMsgRecord(item, sendMsg) {
      this.GetList();
      if (item.sessionId) {
        sendMsg = false
      }
      this.form = {
        recordId: item.id,
        commoditySpecifications: item.commoditySpecifications,
        state: item.state,
        buyNum: item.buyNum,
        reasonsRefusal: null,
        remark: null,
        buyUserId: item.buyUserId,
        buyUserName:item.buyUserName,
        prescriptionDiagnosedDisease: item.prescriptionDiagnosedDisease,
        drugUsage: item.drugUsage,
        prescriptionCommodity: item.prescriptionCommodity,
        sendMsg: sendMsg,
        sessionId: item.sessionId
      }

      this.$bus.$emit('showDialog', this.form);
    },
    showMsgInfo(item) {
      var _this = this;
      _this.caseInfo = item
      _this.dialogMsgInfo = true
      _this.CommodityDomain.GetInterviewRecord(item.id,
        function (data) {
          if (data.data.length > 0) {
            _this.startSequenceTime = data.data[0].sequence
            _this.endSequenceTime = data.data[(data.data.length) - 1].sequence
            _this.MsgDomain.GetMessagesBySequence(data.data[0].sessionId, data.data[(data.data.length) - 1].sequence, data.data[0].sequence,
              function (data) {
                if (data.data.length > 0) {
                  _this.messageList = data.data

                }
              },
              function (error) {
                console.log(error);
              }
            );
          }
        },
        function (error) {
          console.log(error);
        }
      );
    },
    submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var str = `${_this.form.recordId}/${_this.form.state}/${_this.form.buyNum}/`
          if (_this.type==10) {
            str = str + `%20/${_this.form.remark}`
          } else {
            str = str + `${_this.form.reasonsRefusal}/%20`
          }
          _this.CommodityDomain.Audit(str, function (data) {
            _this.$refs['form'].clearValidate()
            _this.formDialog = false
            _this.GetList();
            if (_this.type == 10) {
              _this.form.state = 10
              _this.$bus.$emit('isOK', _this.form);
            } else {
              _this.form.state = -1
              _this.$bus.$emit('isRefuse', _this.form);
            }
          },
            function (error) {
              console.log(error);
            }
          );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel() {
      this.form = {
        num: 1
      }
      this.formDialog = false
    }
  },
};
</script>

<style scoped>
/*content*/


.table-top {
  /* margin-bottom: 15px; */
}

.line {
  border-top: 1px solid #E6E6E6;

}

.box {
  margin: 20px 0;
}

.leftImg {
  width: 150px;
  height: 150px;
}

.right {
  width: 100%;
}

.item {
  height: 30px;
  line-height: 30px;
}

/* .item:last-child{
  margin-bottom: 10px;
} */
.item .leftLable {
  margin-left: 5px;
  width: 73px;
}

.item .rightValue {
  width: calc(100% - 73px);
}

.box /deep/.el-form-item {
  margin-bottom: 5px;
}

.caseImg {
  width: 100px;
  height: auto;
}

.caseImg img {
  width: 100%;
  height: 100%;
}

.drawer-box {
  padding: 30px;
}

.detail-item {
  position: relative;
  margin-bottom: 20px;
}

.detail-item .detail-tit {
  font-size: 18px;
}

.detail-item>p {
  margin: 5px 0;
}

.detail-item>p span {
  display: inline-block;
  width: 100px;
  text-align: right;
}

.detail-item .status {
  position: absolute;
  right: 5px;
  top: 5px;
}

.shop {
  color: var(--baseColor);
}

.user {
  color: orange;
}

.detail-item .status .text-orange {
  color: orange;
}

.detail-item .status .text-blue {
  color: rgb(47, 0, 255);
}

.detail-item .status p.text-danger {
  color: red;
}

.detail-item .status p.text-succes {
  color: forestgreen;
}
</style>
